<template>
<section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-title">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="page-title float-left">Add Menu</h3>
                            <router-link class="float-right" to="/website-management/menu">
                                <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form class="pt-2">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name">Title</label>
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name">Sub Title </label>
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for=""> Status </label>
                                            <div class="form-group">
                                                <label class="switch">
                                                    <input type="checkbox" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name">URL</label>
                                            <input type="text" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name">Banner Type</label>
                                            <select class="custom-select form-control">
                                                <option selected> </option>
                                                <option> Promotion Banner </option>
                                                <option> Main Banner </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name">Image</label>
                                            <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions" :useCustomSlot="true">
                                                <div class="dropzone-custom-content">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24" fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload" color="">
                                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                        <polyline points="17 8 12 3 7 8"></polyline>
                                                        <line x1="12" y1="3" x2="12" y2="15"></line>
                                                    </svg>
                                                    <div class="subtitle">
                                                        <span> Drop File or </span>
                                                        <span style="color: #eb2027;"> Browse</span>
                                                    </div>
                                                </div>
                                            </vue-dropzone>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group float-right mb-0 pt-3">
                                    <button class="btn btn btn-add btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            showLoader: false,
            invoice: {
                name: "",
                active: true,
            },
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
                maxFilesize: 10,
            },
            submitted: false,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.dropzone {
    padding: 40px 20px !important;
    min-height: 175px !important;
}
</style>
